var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mt-1 pa-1",
      attrs: { outlined: "", color: "#e8eaf6" },
      on: {
        click: function ($event) {
          _vm.isList ? _vm.navigateOrder(_vm.model.id) : false
        },
      },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-1 pt-0 pb-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0 my-auto", attrs: { cols: "3" } },
                [
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(_vm._s(_vm.model.number)),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "7" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _vm.type === "supplier"
                            ? [
                                _vm._v(
                                  " " + _vm._s(_vm.model.suppliername) + " "
                                ),
                              ]
                            : _vm.type === "transfer"
                            ? [
                                _vm._v(
                                  " S: " +
                                    _vm._s(_vm.model.sourcewarehouse) +
                                    " "
                                ),
                              ]
                            : _vm.type === "adjustment"
                            ? [
                                _vm._v(
                                  " W: " + _vm._s(_vm.model.warehousename) + " "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.type === "transfer"
                        ? _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _vm._v(
                                " D: " + _vm._s(_vm.model.destwarehouse) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "7" } },
                        [
                          _vm._v(
                            " O: " +
                              _vm._s(
                                _vm._f("dateFormat")(_vm.model.orderdate)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.isList &&
                      (_vm.type === "supplier" || _vm.type === "adjustment")
                        ? _c(
                            "v-col",
                            {
                              staticClass: "pa-0 text-end pr-2",
                              attrs: { cols: "5" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("message.product.qty")) +
                                  ": "
                              ),
                              _vm.type === "supplier"
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("absoluteNumber")(
                                          _vm.model.receivedqty
                                        )
                                      )
                                    ),
                                  ]
                                : _vm.type === "adjustment"
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("absoluteNumber")(_vm.model.qty)
                                      )
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0 my-auto text-right",
                  attrs: { cols: "2" },
                },
                [
                  _vm.isList
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [_c("v-icon", [_vm._v("mdi-information-outline")])],
                        1
                      )
                    : _c("h4", [
                        _vm._v(
                          " +" +
                            _vm._s(
                              _vm._f("absoluteNumber")(_vm.model.receivedqty)
                            ) +
                            " "
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }